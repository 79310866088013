.logo {
  position: absolute;
  width: 119px;
  height: 122px;
  left: 1361px;
  top: 32px;
  border-radius: 70px;
}

.logo:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .logo {
    display: flex;
    left: 25px;
  }
}
