body {
  background-color: #554646;
}

.tree {
  position: absolute;
  width: 512px;
  height: 512px;
  left: 815px;
  top: 170px;
}

.name {
  position: absolute;
  width: 271px;
  height: 67px;
  left: 120px;
  top: 282px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 45px;
  line-height: 67px;
  color: #e6e4ec;
}

.first {
  color: #ff6600;
}

.job {
  position: absolute;
  width: 196px;
  height: 67px;
  left: 120px;
  top: 348px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 46px;
  line-height: 67px;
  /* identical to box height, or 146% */

  color: #e6e4ec;
}

.job2 {
  position: absolute;
  width: 218px;
  height: 67px;
  left: 325px;
  top: 348px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 49px;
  line-height: 67px;
  /* identical to box height, or 137% */

  color: #477b63;
}

.aboutMe {
  position: absolute;
  width: 486px;
  height: 102px;
  left: 120px;
  top: 426px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 34px;
  /* or 148% */

  color: #e6e4ec;
}

.button1 {
  position: absolute;
  left: 120px;
  top: 560px;
}

.button2 {
  position: absolute;
  left: 350px;
  top: 560px;
}

@media only screen and (max-width: 600px) {
  img {
    display: none;
  }

  .name {
    left: 20px;
    top: 152px;
  }

  .aboutMe {
    width: 285px;
    left: 20px;
    top: 310px;
  }

  .job {
    display: none;
  }

  .job2 {
    left: 20px;
    top: 220px;
  }

  .button1 {
    left: 10px;
    top: 550px;
  }

  .button2 {
    left: 187px;
    top: 550px;
  }

  button {
    width: 170px;
  }
}
