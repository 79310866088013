.about {
  position: absolute;
  width: 150px;
  height: 45px;
  left: 152px;
  top: 182px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 29px;
  line-height: 45px;
  /* identical to box height, or 154% */

  letter-spacing: 2px;

  color: #477b63;
}

.line {
  position: absolute;
  width: 550px;
  height: 34px;
  left: 152px;
  top: 253px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 34px;
  /* identical to box height, or 170% */

  color: #e6e4ec;
}

.lilcolor {
  color: #ff6600;
}

.line2 {
  position: absolute;
  width: 800px;
  height: 68px;
  left: 152px;
  top: 313px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 34px;
  /* or 179% */

  color: #e6e4ec;
}

.line3 {
  position: absolute;
  width: 800px;
  height: 68px;
  left: 152px;
  top: 412px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 34px;
  /* or 170% */

  color: #e6e4ec;
}

.line4 {
  position: absolute;
  width: 800px;
  height: 34px;
  left: 152px;
  top: 504px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 34px;
  /* identical to box height, or 170% */

  color: #e6e4ec;
}

.trees {
  position: absolute;
  width: 512px;
  height: 451px;
  left: 982px;
  top: 217px;
}

.button3 {
  position: absolute;
  left: 152px;
  top: 571px;
}

.green {
  color: #e6e4ec;
  background-color: #477b63;
}

.pink {
  color: #e11c79;
}

.anchorGit {
  text-decoration: none;
}

.anchorLink {
  text-decoration: none;
  color: #e6e4ec;
}

.green:hover {
  background-color: #ff6600;
}

.movLink {
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .about {
    left: 90px;
  }

  .line {
    left: 90px;
    height: 150px;
    width: 225px;
  }

  .line2 {
    display: none;
  }

  .line3 {
    display: none;
  }

  .line4 {
    left: 90px;
    height: 150px;
    width: 250px;
    top: 380px;
  }

  .button3 {
    left: 90px;
  }
}
