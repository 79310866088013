.orange {
  position: absolute;
  width: 75px;
  height: 45px;
  left: 152px;
  top: 71px;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 27px;
  line-height: 45px;
  /* identical to box height, or 165% */

  letter-spacing: 2px;

  color: #ff6600;
}

.skills1 {
  position: absolute;
  width: 837px;
  height: 68px;
  left: 152px;
  top: 139px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 34px;
  /* or 170% */

  color: #e6e4ec;
}

.skills2 {
  position: absolute;
  width: 837px;
  height: 68px;
  left: 152px;
  top: 185px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 34px;
  /* or 170% */

  color: #e6e4ec;
}

.skills3 {
  position: absolute;
  width: 937px;
  height: 68px;
  left: 152px;
  top: 235px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 34px;
  /* or 170% */

  color: #e6e4ec;
}

.skills4 {
  position: absolute;
  width: 627px;
  height: 14px;
  left: 152px;
  top: 285px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 34px;
  /* identical to box height, or 179% */

  color: #e6e4ec;
}

.lang {
  position: absolute;
  width: 117px;
  height: 23px;
  left: 179px;
  top: 377px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height, or 135% */

  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ff6600;
}

.tools {
  position: absolute;
  width: 70px;
  height: 23px;
  left: 495px;
  top: 377px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height, or 135% */

  letter-spacing: 2px;
  text-transform: uppercase;

  color: #ff6600;
}

.frame {
  position: absolute;
  width: 146px;
  height: 23px;
  left: 800px;
  top: 377px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height, or 143% */

  letter-spacing: 2px;
  text-transform: uppercase;

  color: #ff6600;
}

.add {
  position: absolute;
  width: 116px;
  height: 23px;
  left: 1099px;
  top: 377px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height, or 135% */

  letter-spacing: 2px;
  text-transform: uppercase;

  color: #ff6600;
}

.css {
  position: absolute;
  width: 100px;
  height: 25px;
  left: 179px;
  top: 421px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 142% */

  color: #e6e4ec;
}

.html {
  position: absolute;
  width: 197px;
  height: 25px;
  left: 179px;
  top: 456px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 151% */

  color: #e6e4ec;
}

.js {
  position: absolute;
  width: 116px;
  height: 25px;
  left: 179px;
  top: 491px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 161% */

  color: #e6e4ec;
}

.bash {
  position: absolute;
  width: 47px;
  height: 25px;
  left: 179px;
  top: 526px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 161% */

  color: #e6e4ec;
}

.post {
  position: absolute;
  width: 87px;
  height: 25px;
  left: 179px;
  top: 561px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 161% */

  color: #e6e4ec;
}

.web {
  position: absolute;
  width: 192px;
  height: 53px;
  left: 500px;
  top: 423px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* or 151% */

  color: #e6e4ec;
}

.git {
  position: absolute;
  width: 174px;
  height: 25px;
  left: 500px;
  top: 470px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 151% */

  color: #e6e4ec;
}

.various {
  position: absolute;
  width: 144px;
  height: 23px;
  left: 500px;
  top: 520px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* or 161% */

  color: #e6e4ec;
}

.access {
  position: absolute;
  width: 155px;
  height: 25px;
  left: 500px;
  top: 561px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 161% */

  color: #e6e4ec;
}

.react {
  position: absolute;
  width: 134px;
  height: 25px;
  left: 800px;
  top: 421px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 151% */

  color: #e6e4ec;
}

.gatsby {
  position: absolute;
  width: 127px;
  height: 25px;
  left: 800px;
  top: 456px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 142% */

  color: #e6e4ec;
}

.node {
  position: absolute;
  width: 90px;
  height: 25px;
  left: 800px;
  top: 491px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 142% */

  color: #e6e4ec;
}

.next {
  position: absolute;
  width: 108px;
  height: 25px;
  left: 800px;
  top: 526px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 151% */

  color: #e6e4ec;
}

.boot {
  position: absolute;
  width: 80px;
  height: 25px;
  left: 800px;
  top: 561px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 151% */

  color: #e6e4ec;
}

.linux {
  position: absolute;
  width: 179px;
  height: 25px;
  left: 1100px;
  top: 421px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 161% */

  color: #e6e4ec;
}

.sales {
  position: absolute;
  width: 98px;
  height: 25px;
  left: 1100px;
  top: 456px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 151% */

  color: #e6e4ec;
}

.figma {
  position: absolute;
  width: 131px;
  height: 25px;
  left: 1100px;
  top: 491px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 161% */

  color: #e6e4ec;
}

.fiveS {
  position: absolute;
  width: 189px;
  height: 25px;
  left: 1100px;
  top: 526px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 161% */

  color: #e6e4ec;
}

.AWS {
  position: absolute;
  width: 175px;
  height: 34px;
  left: 1100px;
  top: 561px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 151% */

  color: #e6e4ec;
}

.skillButton {
  position: absolute;
  left: 150px;
  top: 640px;
}

@media only screen and (max-width: 600px) {
  .skillsCont {
    display: none;
  }

  .skills1 {
    left: 90px;
    width: 250px;
    height: 150px;
    top: 240px;
  }

  .skills2 {
    left: 90px;
    width: 250px;
    height: 150px;
    top: 410px;
  }

  .skills3 {
    display: none;
  }

  .skills4 {
    display: none;
  }

  .skillButton {
    left: 90px;
    top: 605px;
  }

  .orange {
    top: 200px;
    left: 90px;
  }
}
